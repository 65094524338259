jQuery(document).ready(function($) {
	// Mobile menu
	$(".mobile-navigation-menu").mmenu({
		extensions: ["position-right"],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	}, {
		fixedElements: {
			elemInsertMethod: "prependTo"
		}
	});

	$(".mobile-menu-button").click(function() {
		$(".mobile-navigation-menu").data( "mmenu" ).open();
	});

	// Fit Vids
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-content").fitVids({ ignore: "nofit" });
		$(".homeboxes").fitVids({ ignore: "nofit" });
	}

	// Annoucement region
	$( ".closeAlertButton" ).click(function() {
		$( ".nav-section-container" ).removeClass( "with-alert" );
		$(".alert-wrap").hide();
		setCookie("alertRegion", "isShown");
	});

	function setCookie(cname, cvalue) {
		document.cookie = cname + "=" + cvalue + ";path=/";
	}

	if ( document.cookie.indexOf("alertRegion=") == -1 ) {
		$( ".nav-section-container" ).addClass( "with-alert" );
		$(".alert-wrap").show();
	}

	//  Webflow: Interactions: Init
	Webflow.require('ix').init([
		{slug: "dropdown",name: "dropdown",value: {style: {},triggers: [{type: "hover",selector: ".dropdown",stepsA: [{display: "flex",opacity: 1,height: "auto",transition: "height 250ms ease 0, opacity 250ms ease 0"}],stepsB: [{opacity: 0,height: "0px",transition: "opacity 200 ease 0, height 200 ease 0"},{display: "none"}]}]}},
		{slug: "side-icon-over",name: "side icon over",value: {style: {},triggers: [{type: "hover",selector: ".sideicon-text",stepsA: [{opacity: 1,transition: "opacity 200 ease 0"}],stepsB: [{opacity: 0,transition: "opacity 200 ease 0"}]}]}},
		{slug: "side-icon-over-3",name: "side icon over 3",value: {style: {},triggers: [{type: "hover",selector: ".sideicon-text-2",stepsA: [{opacity: 1,transition: "opacity 200 ease 0"}],stepsB: [{opacity: 0,transition: "opacity 200 ease 0"}]}]}},
		{slug: "side-icon-over-2",name: "side icon over 2",value: {style: {},triggers: [{type: "hover",selector: ".sideicon-text-3",stepsA: [{opacity: 1,transition: "opacity 200 ease 0"}],stepsB: [{opacity: 0,transition: "opacity 200 ease 0"}]}]}},
		{slug: "close-alert",name: "Close Alert",value: {style: {},triggers: [{type: "click",selector: ".alert-section",stepsA: [{display: "none"}],stepsB: []},{type: "click",selector: ".nav-section",preserve3d: true,stepsA: [{x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "nav-hover",name: "nav hover",value: {style: {},triggers: [{type: "hover",selector: ".nav-underline",stepsA: [{opacity: 1,width: "50%",height: "2px",transition: "width 200 ease 0, opacity 200 ease 0, height 200 ease 0"}],stepsB: [{opacity: 0,width: "0px",transition: "opacity 200 ease 0, width 200 ease 0"}]}]}},
		{slug: "nav-hover-2",name: "nav hover 2",value: {style: {},triggers: [{type: "hover",selector: ".nav-underline-5",stepsA: [{opacity: 1,width: "50%",height: "2px",transition: "width 200 ease 0, opacity 200 ease 0, height 200 ease 0"}],stepsB: [{opacity: 0,width: "0px",transition: "opacity 200 ease 0, width 200 ease 0"}]}]}},
		{slug: "nav-hover-3",name: "nav hover 3",value: {style: {},triggers: [{type: "hover",selector: ".nav-underline-4",stepsA: [{opacity: 1,width: "50%",height: "2px",transition: "width 200 ease 0, opacity 200 ease 0, height 200 ease 0"}],stepsB: [{opacity: 0,width: "0px",transition: "opacity 200 ease 0, width 200 ease 0"}]}]}},
		{slug: "nav-hover-4",name: "nav hover 4",value: {style: {},triggers: [{type: "hover",selector: ".nav-underline-3",stepsA: [{opacity: 1,width: "50%",height: "2px",transition: "width 200 ease 0, opacity 200 ease 0, height 200 ease 0"}],stepsB: [{opacity: 0,width: "0px",transition: "opacity 200 ease 0, width 200 ease 0"}]}]}},
		{slug: "nav-hover-5",name: "nav hover 5",value: {style: {},triggers: [{type: "hover",selector: ".nav-underline-2",stepsA: [{opacity: 1,width: "50%",height: "2px",transition: "width 200 ease 0, opacity 200 ease 0, height 200 ease 0"}],stepsB: [{opacity: 0,width: "0px",transition: "opacity 200 ease 0, width 200 ease 0"}]}]}},
		{slug: "search",name: "search",value: {style: {},triggers: [{type: "click",selector: ".search-input-3",stepsA: [{opacity: 1,height: "auto",transition: "height 200 ease 0, opacity 200 ease 0"}],stepsB: [{opacity: 0,height: "0px",transition: "opacity 200 ease 0, height 200 ease 0"}]}]}}
	]);
	/**
	 * ----------------------------------------------------------------------
	 * Webflow: Interactions 2.0: Init
	 */
	Webflow.require('ix2').init(
		{events: {"e-3": {id: "e-3",eventTypeId: "SCROLL_INTO_VIEW",action: {id: "",actionTypeId: "GROW_EFFECT",config: {actionListId: "growIn",autoStopEventId: "e-4"},instant: false},mediaQueries: ["main","medium","small","tiny"],target: {appliesTo: "ELEMENT",styleBlockIds: [],id: "5ed7f7cd1f4ddd6c1f539f59|51aae573-a4d6-bb54-9e2c-b920d69b745d"},config: {loop: false,playInReverse: false,scrollOffsetValue: 0,scrollOffsetUnit: "%",delay: 200,direction: null,effectIn: true},createdOn: 1591289293205}},actionLists: {growIn: {id: "growIn",useFirstGroupAsInitialState: true,actionItemGroups: [{actionItems: [{actionTypeId: "STYLE_OPACITY",config: {delay: 0,duration: 0,target: {id: "N/A",appliesTo: "TRIGGER_ELEMENT",useEventTarget: true},value: 0}}]},{actionItems: [{actionTypeId: "TRANSFORM_SCALE",config: {delay: 0,duration: 0,target: {id: "N/A",appliesTo: "TRIGGER_ELEMENT",useEventTarget: true},xValue: 0.7500000000000001,yValue: 0.7500000000000001}}]},{actionItems: [{actionTypeId: "TRANSFORM_SCALE",config: {delay: 0,easing: "outQuart",duration: 1000,target: {id: "N/A",appliesTo: "TRIGGER_ELEMENT",useEventTarget: true},xValue: 1,yValue: 1}},{actionTypeId: "STYLE_OPACITY",config: {delay: 0,easing: "outQuart",duration: 1000,target: {id: "N/A",appliesTo: "TRIGGER_ELEMENT",useEventTarget: true},value: 1}}]}]}},site: {mediaQueries: [{key: "main",min: 992,max: 10000},{key: "medium",min: 768,max: 991},{key: "small",min: 480,max: 767},{key: "tiny",min: 0,max: 479}]}}
	);

	// Accessible menu
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-link").click(function(event) {
			if ( !$(this).parents(".hasChildren").hasClass("open") ) {
				$(".nav-link-wrapper").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".nav-link-wrapper").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".desktop-navigation-menu").accessibleMenu();
});
